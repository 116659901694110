/* eslint-disable */
// import * as yup from 'yup';
import { i18n } from '@/locales/i18n';
// import Roles from '@/security/roles';

import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
import StringArrayField from '@/shared/fields/string-array-field';
import BooleanField from '@/shared/fields/boolean-field';
import DateTimeField from '@/shared/fields/date-time-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import IntegerField from '@/shared/fields/integer-field';
// import ImagesField from '@/shared/fields/images-field';
// import DateTimeRangeField from '@/shared/fields/date-time-range-field';
// import GenericField from '@/shared/fields/generic-field';
import { GenericModel } from '@/shared/model/generic-model';

// class RolesField extends StringArrayField {
//   constructor(name, label, config) {
//     super(name, label, config);
//     this.options = Roles.selectOptions;
//   }

//   forExport() {
//     return yup
//       .mixed()
//       .label(this.label)
//       .transform((values) =>
//         values
//           ? values
//               .map((value) => Roles.labelOf(value))
//               .join(' ')
//           : null,
//       );
//   }
// }

// class EmailsField extends StringArrayField {
//   forFormRules() {
//     let yupValidator = yup
//       .array()
//       .label(this.label)
//       .of(
//         yup
//           .string()
//           .email(i18n('user.validations.email'))
//           .label(i18n('user.fields.email'))
//           .max(255)
//           .required(),
//       );

//     if (this.required) {
//       yupValidator = yupValidator.required();
//     }

//     const validator = (rule, value, callback) => {
//       try {
//         yupValidator.validateSync(value);
//         callback();
//       } catch (error) {
//         callback(error);
//       }
//     };

//     return [{ validator }];
//   }
// }

function label(name) {
  return i18n(`iam.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  authenticationUid: new StringField('authenticationUid', label('authenticationUid')),
  userName: new StringField('userName', label('userName')),
  email: new StringField('email', label('email'), {
    required: true,
    max: 255,
  }),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), {
    matches: /^[0-9]/,
    max: 24,
  }),
  status: new EnumeratorField('status', label('status'), [
    {
      id: 'enabled',
      label: i18n('iam.enabled'),
    },
    {
      id: 'disabled',
      label: i18n('iam.disabled'),
    },
    {
      id: 'removed',
      label: i18n('iam.removed'),
    },
  ]),
  role: new EnumeratorField('role', label('role'), [
    {
      id: 'user',
      label: i18n('iam.roles.user'),
    },
    {
      id: 'admin',
      label: i18n('iam.roles.admin'),
    }
  ]),
  lang: new StringField('lang', label('lang')),
  avatar: new StringField('avatar', label('avatar')),
  pagesAccess: new StringArrayField('pagesAccess', label('pagesAccess')),
  deviceTokens: new StringArrayField('deviceTokens', label('deviceTokens')),
  notification: new BooleanField('notification', label('notification')),
  listedProductsCount: new IntegerField('listedProductsCount', label('listedProductsCount')),
  password: new StringField('password', label('password'), { required: true }),
  rememberMe: new BooleanField('rememberMe', label('rememberMe')),
  
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdBy: new StringField('createdBy', label('createdBy')),
  updatedBy: new StringField('updatedBy', label('updatedBy')),
  // createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class AdminModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
